<template>
    <div class="my-second gonggao bgcolor">
        <Head :title="$t('home.anno')" :show="true" ></Head>
        <div class="common-box gonggao-box">
            <div class="gonggao-list">
                <van-cell-group  >
                    <van-cell  
                    :to="{name:'home-gaodetail',query:{id:l.id}}"
                    v-for="(l,i) in gongGaoList" :key="i" :title="l.title"  is-link   
                    :label="l.time"
                    />
                </van-cell-group>
            </div>
        </div>
    </div>
</template>


<script>
import {mapState,mapMutations} from "vuex"
export default {
    computed:{
        ...mapState(['gongGaoList'])
    },
    methods:{
        ...mapMutations(['setgongGaoList'])
    },
    mounted(){
        if(!this.gongGaoList.length>0){
            // 获取公告列表
            this.$ajax.announcement()
            .then(res=>{
                this.setgongGaoList(res.data.anno)
            })
        }
    }
}
</script>
